<template>
  <div class="product-page">
    <div class="d-flex align-center justify-space-between">
      <h1>Collections</h1>
      <v-btn color="primary" depressed @click="$router.push({ name: 'website.collections.create' })"
        ><v-icon left>mdi-plus</v-icon> Add collection</v-btn
      >
    </div>
    <Collections />
  </div>
</template>

<script>
import Collections from './components/Collections';

export default {
  components: { Collections },
};
</script>
